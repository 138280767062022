@import 'src/app/mixins';

.measurements-page {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  width: 100%;
  max-width: 500px;
  height: 100%;
  padding: 10px 0;

  .measurements-page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .title {
    align-self: center;
  }

  .model-loader-container {
    align-self: center;

    .model-loader {
      position: relative;
      top: auto;
      left: auto;
      transform: none;

      div {
        border-color: var(--blue) transparent transparent transparent;
      }
    }
  }

  .scene-model {
    height: 400px;
  }

  .body-part-radio-group {
    .radio-button {
      // TODO: сделать без important
      padding: 10px !important;
    }
  }
}

.measurement-row-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;

  .measurement-row {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .measurement-item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
      justify-content: center;
    }
  }
}

@include respond-below(mobile) {
  .measurements-page {
    height: calc(100dvh);
  }
}
