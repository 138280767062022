$border-radius: 5px;

.radio-group-form {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .radio-group {
    display: flex;

    .radio-button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      cursor: pointer;
      background-color: var(--grey1);
      transition: all 0.5s;

      .default-text {
        color: var(--grey);
      }

      input[type='radio'] {
        display: none;
      }

      &.top-left {
        border-top-left-radius: $border-radius;
      }

      &.bottom-left {
        border-bottom-left-radius: $border-radius;
      }

      &.top-right {
        border-top-right-radius: $border-radius;
      }

      &.bottom-right {
        border-bottom-right-radius: $border-radius;
      }

      &.checked {
        background-color: var(--blue);

        .default-text {
          color: var(--white);
        }
      }
    }

    &.vertical {
      flex-direction: column;
    }

    &.horizontal {
      flex-direction: row;
      width: 100%;

      .radio-button {
        width: 100%;
      }
    }

    &.grid {
      display: grid;
      grid-template-columns: repeat(var(--radio-group-columns), 1fr);
    }
  }
}
