.default-text {
  color: var(--black);
}

.text3 {
  font-size: 22px;
  font-weight: 400;
  line-height: 25px;
}

.text3-m {
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
}

.text4 {
  font-size: 20px;
  font-weight: 300;
  line-height: 23px;
}

.text5 {
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
}

.text6 {
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

.text7 {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
