.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  padding: 20px 10px;
  border-radius: 3px;
  transition: all 0.5s;

  .button-content {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    width: fit-content;
    height: max-content;
    white-space: nowrap;

    &.children {
      justify-content: space-between;
      width: 100%;
    }
  }

  svg,
  .default-text {
    transition: all 0.5s;
  }

  &:disabled {
    background-color: var(--grey) !important;
    background-image: none !important;
    border: 0 !important;
    box-shadow: none !important;

    svg,
    .default-text {
      color: var(--grey2) !important;
    }
  }

  &.filled {
    background: var(--blue);

    svg,
    .default-text {
      color: var(--white);
    }

    &:hover {
      background: var(--blue1);
    }
  }

  &.outlined {
    box-shadow: 0 0 0 1px var(--blue3);

    svg,
    .default-text {
      color: var(--blue3);
    }

    &:hover {
      background: var(--blue3);

      svg,
      .default-text {
        color: var(--white);
      }
    }
  }
}
