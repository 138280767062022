.parameters-page {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  max-width: 500px;

  .parameters-page-logo {
    align-self: center;
  }

  .your-parameters-header {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }

  .your-parameters-footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;

    @media screen and (height <= 700px) {
      padding-bottom: 15px;
    }
  }

  .inputs {
    display: flex;
    gap: 20px;
  }

  .errors {
    color: var(--red);
  }

  .body-shape-radio {
    display: flex;
    flex-direction: column;
    align-items: center;

    .image {
      width: 30px;
      height: 90px;
    }
  }

  @media screen and (height <= 700px) {
    padding-top: 15px;
  }

  @media screen and (height <= 600px) {
    padding-top: 30%;
  }
}

// TODO: уменьшить паддинги у кнопки, чтобы избавиться от подобного
@media screen and (height <= 800px) {
  .button {
    height: 35px;
  }
}
