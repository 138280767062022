:root {
  --grey: #838289;
  --grey1: #e2e1eb;
  --grey2: #615e5e;
  --grey3: #8a8a8a;
  --black: #2b2c48;
  --blue: #3846cd;
  --blue-transparent: #3846cd80;
  --blue1: #5076ff;
  --blue3: #71b2ff;
  --white: #fff;
  --white1: #f8faff;
  --red: #f24949;
}
