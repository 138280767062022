.checkbox-wrapper input[type='checkbox'] {
  --active: var(--blue);
  --active-inner: var(--white);
  --focus: 2px var(--blue-transparent);
  --border: var(--grey1);
  --border-hover: var(--blue);
  --background: var(--white);
  --disabled: var(--grey);
  --disabled-inner: var(--grey);

  position: relative;
  display: inline-block;
  width: 21px;
  height: 21px;
  margin: 0;
  vertical-align: top;
  appearance: none;
  cursor: pointer;
  background: var(--b, var(--background));
  border: 1px solid var(--bc, var(--border));
  border-radius: 7px;
  outline: none;
  transition: all 0.3s;

  &::after {
    position: absolute;
    top: 0;
    top: 4px;
    left: 0;
    left: 7px;
    display: block;
    width: 5px;
    height: 9px;
    content: '';
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    opacity: var(--o, 0);
    transition:
      transform var(--d-t, 0.3s) var(--d-t-e, ease),
      opacity var(--d-o, 0.2s);
    transform: rotate(var(--r, 20deg));
  }

  &:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --r: 43deg;
    --o: 1;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }

  &:focus {
    box-shadow: 0 0 0 var(--focus);
  }

  &:disabled {
    --b: var(--disabled);

    opacity: 0.9;

    &:checked {
      --b: var(--disabled-inner);
      --bc: var(--border);
    }
  }

  &:hover:not(:checked, :disabled) {
    --bc: var(--border-hover);
  }
}

.checkbox-wrapper input[type='checkbox'] + label {
  display: inline-block;
  margin-left: 4px;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-wrapper * {
  box-sizing: inherit;
}

.checkbox-wrapper *::before,
.checkbox-wrapper *::after {
  box-sizing: inherit;
}
