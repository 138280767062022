.loader-ring {
  position: absolute;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 35px;
  height: 35px;
  transform: translate(-50%, -50%);

  div {
    position: absolute;
    width: 80%;
    height: 80%;
    margin: 10%;
    border: 3px solid var(--white);
    border-color: var(--white) transparent transparent transparent;
    border-radius: 50%;
    animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;

    &:nth-child(1) {
      animation-delay: -0.45s;
    }

    &:nth-child(2) {
      animation-delay: -0.3s;
    }

    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}

@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
