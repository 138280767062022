@import 'https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap';

* {
  padding: 0;
  margin: 0;
  font-family: Roboto, sans-serif;
  border: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

:focus,
:active {
  outline: none;
}

a,
a:link,
a:visited {
  display: inline-block;
  color: inherit;
  text-decoration: none;
}

a:focus,
a:active {
  outline: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

aside,
nav,
footer,
header,
section,
main {
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-size: inherit;
  font-weight: inherit;
}

ul,
ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

img,
svg {
  max-width: 100%;
  max-height: 100%;
}

address {
  font-style: normal;
}

input,
textarea,
button,
select {
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  -webkit-tap-highlight-color: transparent;
}

input::-ms-clear {
  display: none;
}

button,
input[type='submit'] {
  display: inline-block;
  cursor: pointer;
  background: none;
  background-color: transparent;
  box-shadow: none;
}

input:focus,
input:active,
button:focus,
button:active {
  outline: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

label {
  cursor: pointer;
  -webkit-tap-highlight-color: rgb(255 255 255 / 0%);
  -webkit-tap-highlight-color: transparent;
}

legend {
  display: block;
}
