.info-block {
  display: flex;
  gap: 10px;
  align-items: center;
  align-self: stretch;
  justify-content: center;
  padding: 10px;
  text-align: center;
  background-color: var(--grey1);
  border-radius: 5px;
}
