.input-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  color: var(--black);
  transition: 0.5s;

  .inner-input-container {
    position: relative;
    display: flex;
    padding: 10px 20px;
    background-color: var(--grey1);
    border-radius: 3px;
    transition: 0.5s;

    .input-content {
      width: 100%;
    }

    .suffix {
      color: var(--grey);
    }

    &:hover {
      height: 39px;
      background-color: transparent;
      box-shadow: 0 0 4px 0 rgb(0 0 0 / 25%);
      transition: 0.5s;
    }

    &:focus-within {
      height: 39px;
      background-color: transparent;
      box-shadow: 0 0 0 1px var(--blue);
      transition: 0.5s;
    }

    &:disabled {
      color: var(--secondary-grey);
    }
  }
}
